@font-face {
  font-family: 'Montserrat-bold';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff');
  font-weight: 700 900;  // Adjust according to the range the variable font supports
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-medium';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff');
  font-weight: 500;  // Adjust according to the range the variable font supports
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-semibold';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff');
  font-weight: 600;  // Adjust according to the range the variable font supports
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-regular';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff');
  font-weight: 100 400;  // Adjust according to the range the variable font supports
  font-style: normal;
}


body {
  font-family: 'Montserrat-regular';
  font-weight: 400;  // Regular weight
}

strong {
  font-weight: 700;  // Bold weight
}

h1 {
  font-weight: 900;  // Extra Bold weight
}
